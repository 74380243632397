<template>
    <entries :entries="entries" :loading="loading"></entries>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import entries from '@/components/entries/index'
import entryConstants from '@/constants/entries'

export default {
    title: "Entries",
    data () {
        return {
            loading: false
        }
    },
    methods: {
        ...mapActions({
            fetchEntries: 'fetchEntries'
        })
    },
    computed: {
        ...mapGetters({
            entries: 'getEntries'
        })
    },
    created () {
        this.loading = true
        const data = {timezone: entryConstants.currentTimezone()}
        this.fetchEntries(data)
            .then(() => this.loading = false)
            .catch(() => this.loading = false)
    },
    components: {
        'entries': entries
    }
}
</script>
